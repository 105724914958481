@tailwind base;
@tailwind components;
@tailwind utilities;
.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #fff;
    z-index: 100;
}
[x-cloak] {
    display: none !important;
}
.klaro .cookie-modal .cm-btn.cm-btn-success, .klaro .context-notice .cm-btn.cm-btn-success, .klaro .cookie-notice .cm-btn.cm-btn-success {
    background-color: #308AAC !important;
    background-color: #308AAC !important;
}
.klaro .cookie-modal a, .klaro .context-notice a, .klaro .cookie-notice a {
    color: #308AAC !important;
    color: #308AAC !important;
}
.klaro .cookie-modal .cm-list-input.required:checked+.cm-list-label .slider, .klaro .context-notice .cm-list-input.required:checked+.cm-list-label .slider, .klaro .cookie-notice .cm-list-input.required:checked+.cm-list-label .slider {
    background-color: #308AAC !important;
    background-color: #308AAC !important;
}
.klaro .cookie-modal .cm-list-input:checked+.cm-list-label .slider, .klaro .context-notice .cm-list-input:checked+.cm-list-label .slider, .klaro .cookie-notice .cm-list-input:checked+.cm-list-label .slider {
    background-color: #308AAC !important;
    background-color: #308AAC !important;
}